import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private userService = inject(UserService);
  private authService = inject(AuthService);
  private router = inject(Router);

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {

    const access_token = JSON.parse(localStorage.getItem('access_token'));
    const now = new Date();
    const isAuthenticated = await this.getAuthenticated();
    
    if (!isAuthenticated || (!access_token || now.getTime() > access_token.expiration)) {
      this.authService.logout().subscribe();
      localStorage.removeItem('access_token');
      this.router.navigate(['/auth/login']);
      return false;
    }

    return true;
  }

  getAuthenticated(): Promise<any> {
    return this.userService.getAuthenticated().toPromise()
      .then((data) => {
        this.userService.setData(data);
        return !!data.id
      })
      .catch((error) => {
        return false;
      });
  }
}
