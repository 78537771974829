import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {}

  private user: any;

  getAuthenticated(): Observable<any> {
    return this.http.get<{ token: string }>(`${environment.apiUrl}/user/get-authenticated`);
  }

  setData(data: any): void {
    this.user = data;
  }

  getData(): any {
    return this.user;
  }

  create(data): Observable<any> {
    return this.http.post<{ token: string }>(`${environment.apiUrl}/user`, data);
  }

  update(data): Observable<any> {
    return this.http.put<{ token: string }>(`${environment.apiUrl}/user`, data);
  }
}
